import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BlackListPersonService } from '@services/black-list-person/black-list-person.service';
import { SimilarityItem } from '@models/@core/common/common.model';

@Component({
  selector: 'ngx-black-list-person',
  templateUrl: './black-list-person.component.html',
  styleUrls: ['./black-list-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlackListPersonComponent implements OnInit, OnDestroy {
  public isAlert: InputSignal<boolean> = input<boolean>(false);
  @Output() isBlackListPersonEmit = new EventEmitter();
  public blackList: WritableSignal<SimilarityItem[]> = signal([]);
  public fullName: InputSignal<string> = input<string>('');
  public customerId: InputSignal<string> = input<string>('');
  public birthDate: InputSignal<string> = input<string>('');
  private destroy$: Subject<void> = new Subject<void>();
  private blackListService: BlackListPersonService = inject(BlackListPersonService);

  private set initData(data: SimilarityItem[]) {
    this.blackList.set(data);
  }

  ngOnInit(): void {
    this.getBlackListPerson();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getBlackListPerson() {
    this.blackListService
      .getBlackListPerson(this.fullName(), this.customerId(), this.birthDate())
      .pipe(
        tap((data: SimilarityItem[]) => {
          this.initData = data;
          this.isBlackListPersonEmit.emit();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
