import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { callCenterRole } from '@utils/const';
import { Pagination, User } from '@models/@core/common/common.model';
import { Observable } from 'rxjs';
import { ReadApiService } from '@services/@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class CallCenterStaffService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private baseUrlOidc: string = this.environment.getApiKey('baseUrlOidc');
  getListStaff(page: number = 1, name: string = ''): Observable<Pagination<User>> {
    return this.http.get<Pagination<User>>(
      this.baseUrlOidc +
        `/Administration/api/v1/Users/Search?role=${callCenterRole.id}&name=${name}&pageNumber=${page}&pageSize=20`,
    );
  }

  deleteStaff(id: string): Observable<void> {
    return this.http.delete<void>(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`);
  }
}
