import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Optional, signal, WritableSignal } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BlackListPersonService } from '@services/black-list-person/black-list-person.service';

@Component({
  selector: 'ngx-add-black-list-person-modal',
  templateUrl: './add-black-list-person-modal.html',
  styleUrls: ['./add-black-list-person-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBlackListPersonModal implements OnInit, OnDestroy {
  public form: FormGroup;
  public submitted: WritableSignal<boolean> = signal(false);
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private toaster: ToastrService,
    private blackListService: BlackListPersonService,
    @Optional() private dialogRef: NbWindowRef,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      url: ['', [Validators.required]],
    });
  }

  onSubmit() {
    this.submitted.update(() => true);
    if (this.form.valid) {
      this.blackListService
        .addPersonToBlackList(this.form.value)
        .pipe(
          tap(() => {
            this.toaster.success('Успешно добавлено!');
            this.dialogRef.close('create');
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
