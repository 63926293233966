<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="label">Ф.И.О</label>
    <input
      type="text"
      nbInput
      fullWidth
      placeholder="Ф.И.О"
      formControlName="name"
      [status]="submitted && form.get('name').errors?.['required'] ? 'danger' : 'basic'" />
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1" class="label">Логин</label>
    <input
      type="text"
      nbInput
      fullWidth
      placeholder="Логин"
      formControlName="userName"
      [status]="submitted && form.get('userName').errors?.['required'] ? 'danger' : 'basic'" />
  </div>
  <div class="form-group">
    <label class="label">Новый пароль</label>
    <div class="d-flex">
      <input
        type="text"
        nbInput
        fullWidth
        placeholder="Новый пароль"
        formControlName="password"
        [status]="submitted && form.get('password').errors?.['required'] ? 'danger' : 'basic'" />
      <button type="button" class="ml-2" (click)="generatePassword()" nbButton status="primary">Сгенерировать</button>
    </div>
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1" class="label">Подтвердите пароль</label>
    <input
      type="text"
      nbInput
      fullWidth
      placeholder="Подтвердите пароль"
      formControlName="passwordConfirmation"
      [status]="submitted && form.get('passwordConfirmation').errors?.['required'] ? 'danger' : 'basic'" />
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1" class="label">Роли</label>

    <ngx-search-select
      (searchEmit)="getRoles($event)"
      [control]="form.get('roles')"
      [isRequired]="true"
      [submitted]="submitted"
      [data]="roles"
      returnVal="title"
      [mode]="roleSelectMode"
      [placeholder]="'Выберите роли'"></ngx-search-select>
  </div>
  <button class="d-flex mx-auto" type="submit" nbButton status="primary">Добавить</button>
</form>
