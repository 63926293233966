import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { bankSpecialistRole } from '@utils/const';
import { ReadApiService } from '@services/@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class BankSpecialistStaffService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private baseUrlOidc: string = this.environment.getApiKey('baseUrlOidc');
  getListStaff(page = 1, name = '') {
    return this.http.get(
      this.baseUrlOidc +
        `/Manager/api/v1/Users/Search?role=${bankSpecialistRole.id}&name=${name}&pageNumber=${page}&pageSize=20`,
    );
  }

  public deleteStaff(id: string) {
    return this.http.delete(this.baseUrlOidc + `/Manager/api/v1/Users/${id}`);
  }
}
