import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

const ErrorStatus = {
  SERVER_ERROR: 500,
  NOT_FOUND: 404,
  FORBIDDEN: 403,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  TIMEOUT: 408,
  TOO_MANY_REQUESTS: 429,
};

const ErrorMessages = {
  SERVER_ERROR: 'Ошибка сервера',
  NOT_FOUND: 'Ресурс не найден',
  FORBIDDEN: 'Недостаточно прав',
  BAD_REQUEST: 'Некорректный запрос',
  UNAUTHORIZED: 'Неавторизован',
  TIMEOUT: 'Время ожидания истекло',
  TOO_MANY_REQUESTS: 'Слишком много запросов',
  UNKNOWN_ERROR: 'Неизвестная ошибка',
};

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(private toaster: ToastrService) {}

  handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    const errorTitle = err.status + ' Упс...';
    switch (err.status) {
      case 0:
        errorMessage = ErrorMessages.SERVER_ERROR;
        break;
      case ErrorStatus.SERVER_ERROR:
        errorMessage = ErrorMessages.SERVER_ERROR;
        break;
      case ErrorStatus.NOT_FOUND:
        errorMessage = err.error?.title || ErrorMessages.NOT_FOUND;
        break;
      case ErrorStatus.FORBIDDEN:
        errorMessage = ErrorMessages.FORBIDDEN;
        break;
      case ErrorStatus.BAD_REQUEST:
        errorMessage = ErrorMessages.BAD_REQUEST;
        break;
      case ErrorStatus.UNAUTHORIZED:
        errorMessage = ErrorMessages.UNAUTHORIZED;
        break;
      case ErrorStatus.TIMEOUT:
        errorMessage = ErrorMessages.TIMEOUT;
        break;
      case ErrorStatus.TOO_MANY_REQUESTS:
        errorMessage = ErrorMessages.TOO_MANY_REQUESTS;
        break;
      default:
        if (err.error) {
          if (err.error.errors) {
            for (const property in err.error.errors) {
              errorMessage += `\r\n ${property}: ${err.error.errors[property]}`;
            }
          } else {
            for (const property in err.error) {
              errorMessage += `\r\n ${property}: ${err.error[property]}`;
            }
          }
        } else {
          errorMessage = ErrorMessages.UNKNOWN_ERROR;
        }
        break;
    }
    this.toaster.error(errorMessage, errorTitle);
  }

  public identificationErrors(err) {
    let message = '';

    switch (err.status) {
      case ErrorStatus.NOT_FOUND:
        message = 'Данные для данного ИНН не найдены :(';
        break;
      case ErrorStatus.BAD_REQUEST:
        message = ErrorMessages.BAD_REQUEST;
        break;
      case ErrorStatus.UNAUTHORIZED:
        message = ErrorMessages.UNAUTHORIZED;
        break;
      case ErrorStatus.FORBIDDEN:
        message = ErrorMessages.FORBIDDEN;
        break;
      case ErrorStatus.SERVER_ERROR:
        message = ErrorMessages.SERVER_ERROR;
        break;
      case ErrorStatus.TIMEOUT:
        message = ErrorMessages.TIMEOUT;
        break;
      case ErrorStatus.TOO_MANY_REQUESTS:
        message = ErrorMessages.TOO_MANY_REQUESTS;
        break;
      default:
        message = err.error?.title || ErrorMessages.UNKNOWN_ERROR;
        break;
    }

    return message;
  }
}
