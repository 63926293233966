export const HttpOptions: object = {
  responseType: 'blob',
};
export const IdentificationAnswersRu = {
  pin: [
    {
      label: 'пожалуйста, введите правильный пин',
      value: 'пожалуйста, введите правильный пин',
    },
  ],
  passportFrontSideImage: [
    {
      label: 'пожалуйста, проверьте правильность набора AN',
      value: 'пожалуйста, проверьте правильность набора AN',
    },
    {
      label: 'пожалуйста, проверьте правильность набора ID',
      value: 'пожалуйста, проверьте правильность набора ID',
    },
    {
      label: 'пожалуйста, не закрывайте данные паспорта.',
      value: 'пожалуйста, не закрывайте данные паспорта.',
    },
  ],
  passportBackSideImage: [
    {
      label: 'пожалуйста, сфотографируйтесь с оригиналом паспорта.',
      value: 'пожалуйста, сфотографируйтесь с оригиналом паспорта.',
    },
  ],
  selfieWithPassportImage: [
    {
      label: 'пожалуйста, сфотографируйтесь с паспортом у лица без головного убора.',
      value: 'пожалуйста, сфотографируйтесь с паспортом у лица без головного убора.',
    },
    {
      label: 'пожалуйста, сфотографируйтесь без очков.',
      value: 'пожалуйста, сфотографируйтесь без очков.',
    },
    {
      label: 'пожалуйста, сфотографируйтесь без платка.',
      value: 'пожалуйста, сфотографируйтесь без платка.',
    },
    {
      label: 'пожалуйста, сфотографируйтесь с паспортом, чтобы данные паспорта можно было прочитать.',
      value: 'пожалуйста, сфотографируйтесь с паспортом, чтобы данные паспорта можно было прочитать.',
    },
  ],
  documentNumber: [],
  address: [
    {
      label: 'пожалуйста, введите правильный адрес',
      value: 'пожалуйста, введите правильный адрес',
    },
  ],
};
export const IdentificationAnswersKg = {
  pin: [
    {
      label: 'сураныч, туура PIN киргизиңиз',
      value: 'сураныч, туура PIN киргизиңиз',
    },
  ],
  passportFrontSideImage: [
    {
      label: 'сураныч, AN туура экенин текшериңиз',
      value: 'сураныч, AN туура экенин текшериңиз',
    },
    {
      label: 'сураныч, ID туура экенин текшериңиз',
      value: 'сураныч, AN туура экенин текшериңиз',
    },
    {
      label: 'сураныч, паспорт маалыматыңызды жаппаңыз.',
      value: 'сураныч, паспорт маалыматыңызды жаппаңыз.',
    },
  ],
  passportBackSideImage: [
    {
      label: 'сураныч, паспортуңуздун түп нускасы менен сүрөткө түшүңүз.',
      value: 'сураныч, паспортуңуздун түп нускасы менен сүрөткө түшүңүз.',
    },
  ],
  selfieWithPassportImage: [
    {
      label: 'сураныч, паспорт менен баш кийим жок сүрөткө түшүңүз',
      value: 'сураныч, паспорт менен баш кийим жок сүрөткө түшүңүз',
    },
    {
      label: 'сураныч, көз айнексиз сүрөткө түшүңүз.',
      value: 'сураныч, көз айнексиз сүрөткө түшүңүз.',
    },
    {
      label: 'сураныч, жоолуксуз суротко түшүңүз.',
      value: 'сураныч, жоолуксуз суротко түшүңүз.',
    },
    {
      label: 'сураныч, паспортуңуздун маалыматтары окуула турагандай кылып сүрөткө түшүңүз',
      value: 'сураныч, паспортуңуздун маалыматтары окуула турагандай кылып сүрөткө түшүңүз',
    },
  ],
  documentNumber: [],
  address: [
    {
      label: 'сураныч, жарактуу даректи киргизиңиз',
      value: 'сураныч, жарактуу даректи киргизиңиз',
    },
  ],
};
export const statusIdentificate = [
  { id: 'FoundInBlackList', text: 'Найден в чёрном списке' },
  { id: 'Pending', text: 'Ожидание' },
  { id: 'Created', text: 'Ожидание' },
  { id: 'Approved', text: 'Одобренный' },
  { id: 'Declined', text: 'Отклоненный' },
  { id: 'Canceled', text: 'Отменено' },
  { id: 'Online', text: 'Онлайн' },
  { id: 'Offline', text: 'Офлайн' },
  { id: 'None', text: 'Не идентифицирован' },
  {
    id: 'Active',
    text: 'Активен',
  },
  {
    id: 'InActive',
    text: 'Неактивен',
  },

  { id: true, text: 'Одобренный' },
  { id: false, text: 'Ожидание' },

  {
    id: 'New',
    text: 'Операция успешно создана',
  },
  {
    id: 'WalletNotFound',
    text: 'Кошелёк не найден',
  },
  {
    id: 'WalletNotActive',
    text: 'Кошелёк не активен',
  },
  {
    id: 'InsufficientWalletBalance',
    text: 'Недостаточно средств на балансе кошелька',
  },
  {
    id: 'InsufficientSanaripBalance',
    text: 'Недостаточно средств на балансе Санарип',
  },
  {
    id: 'SumLessThan',
    text: 'Сумма операции меньше установленного значения',
  },
  {
    id: 'SumGreaterThan',
    text: 'Сумма операции больше установленного значения',
  },
  {
    id: 'ExceedsDailyLimitAmount',
    text: 'Превышен дневной лимит кошелька',
  },
  {
    id: 'ExceedsMonthlyLimitAmount',
    text: 'Превышен месячный лимит кошелька',
  },
  {
    id: 'ExceedsMaxWalletBalance',
    text: 'Превышен максимальный баланс кошелька',
  },
  {
    id: 'ExceedsCrossBorderCharityOneTimeLimitAmount',
    text: 'Превышает единовременный лимит трансграничной благотворительной помощи',
  },
  {
    id: 'ExceedsCrossBorderCharityMonthLimitAmount',
    text: 'Превышает месячный лимит трансграничной благотворительной помощи',
  },

  {
    id: 'ExceedsOneTimeLimitAmount',
    text: 'Превышен единовременный лимит',
  },
  {
    id: 'TransferToYourself',
    text: 'Перевод самому себе',
  },
  {
    id: 'InBlackList',
    text: 'В черном списке',
  },
  {
    id: 'CustomerFoundInBlackList',
    text: 'Клиент в черном списке',
  },
  {
    id: 'FoundInBlackList',
    text: 'Найден в черном списке',
  },
  {
    id: 'ReceiverFoundInBlackList',
    text: 'Получатель в черном списке',
  },
  {
    id: 'PaymentInProcessing',
    text: 'Операция в обработке',
  },
  {
    id: 'TemporaryError',
    text: 'Временная ошибка',
  },
  {
    id: 'Error',
    text: 'Операция в ошибке',
  },
  {
    id: 'Canceled',
    text: 'Операция отменёна',
  },
  {
    id: 'Success',
    text: 'Успешно',
  },
  {
    id: 'Withdraw',
    text: 'Понижение',
  },
  {
    id: 'TopUp',
    text: 'Повышение',
  },

  {
    id: 'PhotoIdentificationRequest',
    text: 'Запрошено',
  },
  {
    id: 'PhotoIdentificationProcess',
    text: 'В процессе',
  },
  {
    id: 'PhotoIdentificationApprove',
    text: 'Одобрено',
  },
  {
    id: 'Timeout',
    text: 'Время истекло',
  },
  {
    id: 'PhotoIdentificationDecline',
    text: 'Отклоненный',
  },
];
export const getStatuses = status => {
  switch (status) {
    case 'Approved':
    case true:
      return { id: status, text: 'Одобренный', status: 'success' };
    case 'Pending':
    case 'Created':
      return { id: status, text: 'Ожидание', status: 'info' };
    case 'Declined':
      return { id: status, text: 'Отклоненный', status: 'danger' };
    case 'Canceled':
      return { id: status, text: 'Отменено', status: 'control' };
    case 'Online':
      return { id: status, text: 'Онлайн', status: 'primary' };
    case 'Offline':
      return { id: status, text: 'Офлайн', status: 'success' };
    case 'None':
      return {
        id: status,
        text: 'Не идентифицирован',
        status: 'control',
      };
    case 'Active':
      return { id: status, text: 'Активен', status: 'success' };
    case 'InActive':
    case false:
      return { id: status, text: 'Неактивен', status: 'danger' };
    case 'New':
      return {
        id: status,
        text: 'Операция успешно создана',
        status: 'success',
      };
    case 'WalletNotFound':
      return { id: status, text: 'Кошелёк не найден', status: 'danger' };
    case 'WalletNotActive':
      return {
        id: status,
        text: 'Кошелёк не активен',
        status: 'control',
      };
    case 'InsufficientWalletBalance':
    case 'InsufficientSanaripBalance':
    case 'SumLessThan':
    case 'SumGreaterThan':
    case 'ExceedsDailyLimitAmount':
    case 'ExceedsMonthlyLimitAmount':
    case 'ExceedsMaxWalletBalance':
    case 'ExceedsCrossBorderCharityOneTimeLimitAmount':
    case 'ExceedsCrossBorderCharityMonthLimitAmount':
    case 'ExceedsOneTimeLimitAmount':
    case 'TransferToYourself':
      return {
        id: status,
        text: 'Недостаточно средств',
        status: 'warning',
      };
    case 'InBlackList':
      return { id: status, text: 'В черном списке', status: 'danger' };
    case 'PaymentInProcessing':
      return {
        id: status,
        text: 'Операция в обработке',
        status: 'primary',
      };
    case 'TemporaryError':
      return { id: status, text: 'Временная ошибка', status: 'danger' };
    case 'Error':
      return { id: status, text: 'Операция в ошибке', status: 'danger' };
    case 'Success':
      return { id: status, text: 'Успешно', status: 'success' };
    case 'Withdraw':
      return { id: status, text: 'Понижение', status: 'danger' };
    case 'TopUp':
      return { id: status, text: 'Повышение', status: 'success' };
    case 'PhotoIdentificationRequest':
      return { id: status, text: 'Запрошено', status: 'info' };
    case 'PhotoIdentificationProcess':
      return { id: status, text: 'В процессе', status: 'primary' };
    case 'PhotoIdentificationApprove':
      return { id: status, text: 'Одобрено', status: 'success' };
    case 'PhotoIdentificationDecline':
      return { id: status, text: 'Отклоненный', status: 'danger' };
    case 'Timeout':
      return { id: status, text: 'Время истекло', status: 'control' };
    default:
      return { id: status, text: 'Unknown', status: 'primary' };
  }
};
export const adminRole = {
  title: 'Администратор',
  id: '626b08f2-1ed8-4928-8ca1-db9a11db88b3',
  name: 'admin',
};
export const operatorRole = {
  title: 'Оператор call-центра',
  id: 'a9e7aebf-6649-41a2-9026-89a5e631bb7b',
  name: 'operator',
};
export const bankSpecialistRole = {
  title: 'Специалист банка',
  id: '12bfdca3-f1c0-470a-a546-999f5f266dd1',
  value: 'bank_specialist',
};
export const auditorRole = {
  title: 'Аудитор',
  id: '13bfdca3-f1c0-470a-a546-999f5f266dd1',
  name: 'auditor',
};

export const accountantRole = {
  title: 'Бухгалтер',
  id: '53178a2e-b687-11ed-afa1-0242ac120010',
  name: 'accountant',
};
export const supervisorRole = {
  title: 'Контролёр',
  id: '53178a2e-b687-11ed-afa1-0242ac120011',
  name: 'supervisor',
};
export const callCenterRole = {
  title: 'Call center',
  id: '53178a2e-b687-11ed-afa1-0242ac120012',
  name: 'call_center',
};

export const amlSpecialistRole = {
  title: 'AML специалист',
  id: '53178a2e-b687-11ed-afa1-0242ac120013',
  name: 'aml_specialist',
};

const kycManagerRole = {
  title: 'KYC Менеджер',
  id: '53178a2e-b687-11ed-afa1-0242ac120002',
  name: 'kyc_manager',
};
export const accessRolesArr = [
  adminRole,
  operatorRole,
  kycManagerRole,
  bankSpecialistRole,
  auditorRole,
  accountantRole,
  supervisorRole,
  callCenterRole,
  amlSpecialistRole,
];
