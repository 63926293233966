import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { downloadFile, getTimeWithTimezone } from '../../utils';
import { Observable } from 'rxjs';
import { Customer } from '@models/customer.model';
import { ReadApiService } from '@services/@core/config/read-api.service';

export interface WalletOperationFilter {
  page: number;
  pageSize: number;
  dateFrom: string;
  dateTo: string;
  operationTypes: string[];
  orderBy: string;
  orderType: string;
}

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private walletSystemUrl: string = this.environment.getApiKey('walletSystemUrl');

  getListUserWallets(id: string) {
    return this.http.get(this.walletSystemUrl + `/DosCredoBank/api/v1/customers/${id}/wallets`);
  }

  getUserDetail(id: string): Observable<Customer> {
    return this.http.get<Customer>(this.walletSystemUrl + `/DosCredoBank/api/v1/customers/${id}`);
  }

  getWalletRegistry(clientId: string, filter) {
    return this.http.get(
      this.walletSystemUrl +
        `/DosCredoBank/api/v1/customers/${clientId}/wallets/operations?dateFrom=${
          filter.from + getTimeWithTimezone()
        }&dateTo=${filter.to + getTimeWithTimezone()}`,
      { responseType: 'blob' },
    );
  }

  downloadWalletRegistry(clientId: string, filter) {
    return this.http.get(
      this.walletSystemUrl +
        `/DosCredoBank/api/v1/customers/${clientId}/wallets/operations/excel-download?dateFrom=${
          filter.from + getTimeWithTimezone()
        }&dateTo=${filter.to + getTimeWithTimezone()}`,
      { responseType: 'blob' },
    );
  }

  getWalletBalance(clientId: string, walletId: string) {
    return this.http.get(
      this.walletSystemUrl + `/DosCredoBank/api/v1/customers/${clientId}/wallets/${walletId}/balance`,
    );
  }

  getWalletOperations(page, clientId, walletId, filter) {
    return this.http.get(
      this.walletSystemUrl +
        `/DosCredoBank/api/v1/customers/${clientId}/wallets/${walletId}/operations?page=${page}&dateFrom=${
          filter.from + getTimeWithTimezone()
        }&dateTo=${filter.to + getTimeWithTimezone()}&operationType=${
          filter.operationType
        }&orderBy=CreatedAt&orderType=desc&pageSize=20`,
    );
  }

  activateUser(id: string) {
    return this.http.patch(this.walletSystemUrl + `/DosCredoBank/api/v1/customers/${id}/unblock`, {});
  }

  deactivateUser(id: string, comment: string) {
    return this.http.patch(this.walletSystemUrl + `/DosCredoBank/api/v1/customers/${id}/block `, { comment });
  }

  activateWallet(id: string) {
    return this.http.put(this.walletSystemUrl + `/DosCredoBank/api/v1/customers/${id}/activate`, {});
  }

  deactivateWallet(id: string, walletId) {
    return this.http.put(
      this.walletSystemUrl + `/DosCredoBank/api/v1/customers/${id}/wallets/${walletId}/deactivate`,
      {},
    );
  }

  getOperationListReports(page: number, filter) {
    const params = {
      dateFrom: filter.from,
      dateTo: filter.to,
    };
    return this.http.get(
      this.walletSystemUrl +
        `/DosCredoBank/api/v1/operations?page=${page}&${
          filter.operationType?.join('')
            ? filter.operationType.map(item => item && `&operationTypes=` + item).join('')
            : ''
        }&orderBy=CreatedAt&orderType=desc&pageSize=20`,
      { params },
    );
  }

  downloadReportsOperations(filter) {
    const params = {
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
    };
    return this.http.get(this.walletSystemUrl + `/DosCredoBank/api/v1/operations/excel-download`, {
      params,
      responseType: 'blob',
    });
  }

  public getEveryDayWalletOperationsReport(args: Partial<WalletOperationFilter>): void {
    this.http
      .get(`${this.walletSystemUrl}/DoscredoBank/api/v1/customers/wallets/operations/excel-download?`, {
        params: { ...args },
        responseType: 'blob',
      })
      .subscribe(blob => {
        const link = window.URL.createObjectURL(blob);
        downloadFile(link, `Ежедневные операции по кошелькам на ${args.dateTo}.xlsx`);
      });
  }

  getDailyReport(page: number, filter) {
    const params = {
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
    };
    return this.http.get(
      this.walletSystemUrl +
        `/DosCredoBank/api/v1/operations/daily-report?page=${page}&orderBy=CreatedAt&orderType=desc&pageSize=20`,
      { params },
    );
  }

  downloadDailyReport(filter) {
    const params = {
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
    };
    return this.http.get(this.walletSystemUrl + `/DosCredoBank/api/v1/operations/daily-report/excel-download`, {
      params,
      responseType: 'blob',
    });
  }

  downloadMonthlyReport(filter) {
    const params = {
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
    };
    return this.http.get(this.walletSystemUrl + `/DosCredoBank/api/v1/reports/wallets/monthly-report/excel-download`, {
      params,
      responseType: 'blob',
    });
  }

  downloadQuarterlyReport(filter: { dateFrom: string; dateTo: string; type: { label: string; value: string } }) {
    const params = new HttpParams()
      .set('dateFrom', filter.dateFrom)
      .set('dateTo', filter.dateTo)
      .set('ownerType', filter.type.value === 'quarterly-report-legal' ? 'Contractor' : 'Customer');
    return this.http.get(
      this.walletSystemUrl + `/DosCredoBank/api/v1/reports/wallets/quarterly-report/excel-download`,
      {
        params: params,
        responseType: 'blob',
      },
    );
  }
}
