import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '@models/@core/config/app-config.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private readonly http: HttpClient = inject(HttpClient);
  private config: AppConfig;

  public load(): Promise<AppConfig> {
    return this.http
      .get<AppConfig>('/assets/config.json')
      .toPromise()
      .then(data => {
        this.config = data;
        return data;
      });
  }

  public getConfig(): AppConfig {
    return this.config;
  }
}
