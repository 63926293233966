import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { LayoutService, StateService } from '../utils';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseUrlInterceptor } from '../interceptors/base-url.interceptor';
import { HttpErrorInterceptor } from '../interceptors/error.interceptor';
import { LoaderInterceptor } from '../interceptors/loader-interceptor';
import { HttpInterceptorService } from '../services/@core/http/http.service';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('admin,user');
  }
}

export const NB_CORE_PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptorService,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BaseUrlInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true,
  },
  // ...DATA_SERVICES,
  // ...NbAuthModule.forRoot({
  //     strategies: [
  //         NbDummyAuthStrategy.setup({
  //             name: 'email',
  //             delay: 3000,
  //         }),
  //     ],
  //     forms: {
  //         login: {
  //             socialLinks,
  //         },
  //         register: {
  //             socialLinks,
  //         },
  //     },
  // }).providers,

  // NbSecurityModule.forRoot({
  //     accessControl: {
  //         guest: {
  //             view: '*',
  //         },
  //         user: {
  //             parent: 'guest',
  //             create: '*',
  //             edit: '*',
  //             remove: '*',
  //         },
  //     },
  // }).providers,
  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
  LayoutService,
  StateService,
];

@NgModule({
  imports: [CommonModule],
  exports: [NbAuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
