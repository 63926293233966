import { Observable } from 'rxjs';
import { inject, Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { DownloadImageService } from '@services/@core/files/download-image.service';

@Pipe({
  name: 'useHttpImgSrc',
  pure: true,
  standalone: true,
})
export class UseHttpImageSourcePipe implements PipeTransform {
  private imageService: DownloadImageService = inject(DownloadImageService);

  transform(imagePath: string): Observable<SafeUrl> {
    return this.imageService.loadImage(imagePath);
  }
}
