import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ngx-date-filter-btns',
  templateUrl: './date-filter-btns.component.html',
  styleUrls: ['./date-filter-btns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterBtnsComponent implements OnInit {
  @Input() form;
  constructor() {}

  initializeForm(fromDate: Date, toDate: Date): void {
    const from = this.formatDate(fromDate);
    toDate.setDate(toDate.getDate() + 1);
    const to = this.formatDate(toDate);

    this.form.patchValue({
      from: [from],
      to: [to],
    });
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();

    return `${year}/${month}/${day}`;
  }

  setPreviousMonth(): void {
    const currentDate = new Date();
    const previousMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const previousMonthLastDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);

    this.initializeForm(previousMonthDate, previousMonthLastDate);
  }

  setCurrentMonth(): void {
    const currentDate = new Date();
    const currentMonthFirstDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const currentMonthLastDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    this.initializeForm(currentMonthFirstDate, currentMonthLastDate);
  }

  setYesterday(): void {
    const currentDate = new Date();
    const yesterdayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);

    this.initializeForm(yesterdayDate, yesterdayDate);
  }

  setToday(): void {
    const currentDate = new Date();

    this.initializeForm(currentDate, currentDate);
  }
  ngOnInit(): void {
    this.setCurrentMonth();
  }
}
