@if (blackList() && blackList().length > 0) {
  @if (!isAlert()) {
    <nb-accordion multi>
      <nb-accordion-item>
        <nb-accordion-item-header class="justify-content-center"> В списке экстремистов </nb-accordion-item-header>
        <nb-accordion-item-body>
          <table class="w-100">
            <tr>
              <td>ФИО</td>
              <td>День рождения</td>
              <td>Cходство</td>
            </tr>
            @for (i of blackList(); track i) {
              <tr>
                <td>{{ i.blackListPerson.fullname }}</td>
                <td>
                  {{ i.blackListPerson.birthDate | date: 'dd.MM.yyyy' }}
                </td>
                <td>{{ i.similarity | percent }}</td>
              </tr>
            }
          </table>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  }
  @if (isAlert()) {
    <nb-alert status="danger" class="black-list-alert">
      <strong class="title">Обнаружен в списке террористов, обратитесь к руководителю!</strong>
      <table class="w-100">
        <tr>
          <td>ФИО</td>
          <td>День рождения</td>
          <td>Cходство</td>
        </tr>
        @for (i of blackList(); track i) {
          <tr>
            <td>{{ i.blackListPerson.fullname }}</td>
            <td>
              {{ i.blackListPerson.birthDate | date: 'dd.MM.yyyy' }}
            </td>
            <td>{{ i.similarity | percent }}</td>
          </tr>
        }
      </table>
    </nb-alert>
  }
}
