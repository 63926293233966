<ng-template #form>
  @if (visible) {
    <div [class]="textareaInclude ? 'big-confirm p-2' : 'p-2'">
      @if (textareaInclude) {
        <label for="inputLastName" class="label">{{ textareaTitle }}</label>
        <textarea fullWidth [formControl]="control" placeholder="Написать ..." nbInput rows="2"></textarea>
      }
      <div class="d-flex justify-content-between">
        <button
          [disabled]="control.invalid"
          (click)="approve()"
          size="small"
          nbButton
          status="success"
          type="button"
          ghost>
          Подтвердить
        </button>
        <button (click)="decline()" size="small" nbButton status="danger" type="button" ghost>Отменить</button>
      </div>
    </div>
  }
</ng-template>
<button
  [class]="title.length > 25 ? 'w-100 default-confirm' : 'w-100'"
  nbButton
  (click)="onClick()"
  [status]="status"
  [disabled]="disabled"
  [ghost]="ghost"
  [nbPopover]="form">
  {{ title }}
</button>
