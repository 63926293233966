import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { Observable, Subject } from 'rxjs';
import { convertToPercentage, genderEnum, maritalStatus, residenceLocationEnum } from '../../../utils';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ApplicationRequestsService } from '../../../services/credit-application/credit.service';
import { BlackListTypes } from '../../../utils/helpers';
import { CustomerProfile, SimilarityItem } from '../../../models/@core/common/common.model';

@Component({
  selector: 'ngx-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit, OnDestroy {
  public userData: CustomerProfile;
  public userData$: Observable<CustomerProfile>;
  public blackListData: SimilarityItem;
  public maritalStatus: string;
  public residenceLoc: string;
  public gender: string;
  public actualLoc: string;
  public operatorName$: Observable<any>;
  public convertToPercentage = convertToPercentage;
  public blackListTypes = BlackListTypes;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private applicationRequestsService: ApplicationRequestsService,
    private route: ActivatedRoute,
    public router: Router,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.userData$ = this.usersService.getUserProfileData(this.route.snapshot.params.id).pipe(
      filter(data => !!data),
      tap(data => {
        this.userData = data;
        this.loadOperatorName(data.id);
        this.getInfo();
        this.getBlackListPerson(data);
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getMaritalStatus() {
    this.maritalStatus = maritalStatus.find(item => item.value === this.userData.maritalStatus)?.text;
  }

  private getGender() {
    this.gender = genderEnum.find(e => e.value === this.userData.gender).text;
  }

  private getResidenceLoc() {
    this.residenceLoc = residenceLocationEnum.find(e => e.value === this.userData.residenceAddress.location)?.text;
  }

  private getActualLoc() {
    this.actualLoc = residenceLocationEnum.find(e => e.value === this.userData.actualAddress.region)?.text;
  }

  private getBlackListPerson(userData: CustomerProfile) {
    this.applicationRequestsService
      .getBlackListPerson(
        userData.surname + ' ' + userData.name + ' ' + userData.patronymic,
        userData.id,
        userData.birthDate,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: data => {
          this.blackListData = data[0];
          this.cd.markForCheck();
        },
      });
  }

  private loadOperatorName(id: string) {
    this.operatorName$ = this.usersService
      .getIdentificationRequest(id)
      .pipe(map(data => data?.items[0]?.processors?.[0]?.processor?.fullname || ''));
  }

  private getInfo() {
    this.getMaritalStatus();
    this.getResidenceLoc();
    this.getGender();
    this.getActualLoc();
  }
}
