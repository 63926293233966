@if (tableData && tableData?.items?.length > 0) {
  <div class="custom-table">
    <angular2-smart-table
      [settings]="settings"
      [source]="tableData.items"
      (delete)="onDelete($event)"
      (edit)="onEdit($event)"
      (userRowSelect)="onRowSelect($event)">
    </angular2-smart-table>
  </div>
  <ngx-pagination (changePageEvent)="changePage($event)" [data]="tableData"></ngx-pagination>
}
@if (tableData && tableData?.items?.length === 0) {
  <h6 class="text text-center">Здесь пока нет {{ productName }}</h6>
}
