import { Pipe, PipeTransform } from '@angular/core';
import { maritalStatus } from '@utils/creditAplicationData';

@Pipe({
  name: 'maritalStatus',
  standalone: true,
  pure: true,
})
export class MaritalStatusPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (!value) {
      return 'Не указано';
    }
    return maritalStatus.find(item => item.value === value)?.text;
  }
}
