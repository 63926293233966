import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { checkRolePermission, getCurrency } from '../../../utils';
import { StatusBadgeComponent } from '../../shared/status-badge/status-badge.component';
import { WalletService } from '@services/wallet/wallet.service';
import { CustomDatePipe } from '../../shared/date-pipe/date.pipe';
import { WalletActionsModalComponent } from '../wallet-actions-modal/wallet-actions-modal.component';
import { NbWindowService } from '@nebular/theme';
import { AuthService } from '@services/@core/auth/auth.service';
import { getPaginationIndex } from '@utils/tableNumbering';

@Component({
  selector: 'ngx-user-wallet',
  templateUrl: './user-wallet.component.html',
  styleUrls: ['./user-wallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWalletComponent implements OnInit, OnDestroy {
  @Input() userData;
  listWallets;
  hasAccesToWalletActions = false;
  isAuditor;
  tableColumns = {
    index: {
      title: '№',
      type: 'number',
      valuePrepareFunction: (value, row, cell) =>
        getPaginationIndex(this.listWallets.page, row.row.index, this.listWallets.pageSize),
    },

    balance: {
      title: 'Баланс',
      type: 'number',
    },
    currency: {
      title: 'Валюта',
      type: 'text',
      valuePrepareFunction: item => getCurrency(item),
    },
    createdAt: {
      title: 'Дата создания',
      type: 'text',
      valuePrepareFunction: item => this.parseDate(item),
    },

    isDefault: {
      title: 'Основной',
      type: 'text',
      valuePrepareFunction: bool => (bool ? 'Да' : 'Нет'),
    },
    status: {
      title: 'Статус',
      type: 'custom',
      renderComponent: StatusBadgeComponent,
      componentInitFunction: StatusBadgeComponent.componentInit,
    },
    custom: {
      title: 'Детали',
      type: 'html',
      valuePrepareFunction: () => ` <a
                          class='color-a'
                        >
                          Подробнее
                        </a>`,
    },
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private walletService: WalletService,
    private toaster: ToastrService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private datePipe: CustomDatePipe,
    private windowService: NbWindowService,
    private authService: AuthService,
  ) {}

  checkAccessToProducts() {
    const userAuthData = this.authService.getUserData();
    this.hasAccesToWalletActions = checkRolePermission(userAuthData.role, ['admin', 'kyc_manager']);
  }

  checkIsAuditor() {
    const userAuthData = this.authService.getUserData();

    this.isAuditor = userAuthData.role.includes('auditor');
    this.cdr.markForCheck();
  }

  parseDate(date) {
    return this.datePipe.transform(date, 'dd.MM.yyyy, HH:mm');
  }

  getListWallets(page = 1) {
    this.walletService
      .getListUserWallets(this.userData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.listWallets = {
          items: res,
          pageCount: 1,
          totalItemCount: res.length,
          page: 1,
          pageSize: res.length,
          hasPreviousPage: false,
          hasNextPage: false,
        };
        this.cdr.markForCheck();
      });
  }

  public openEditModal(data) {
    this.openModal(false, WalletActionsModalComponent, {
      title: 'Кошелек',
      context: { itemData: data, userId: this.userData.id },
    });
  }

  public openModal(closeOnBackdropClick: boolean, component, props) {
    this.windowService
      .open(component, {
        closeOnBackdropClick,
        ...props,
      })
      .onClose.subscribe(val => (val === 'create' || val === 'edit') && this.getListWallets());
  }

  onRowSelect(id) {
    this.router.navigate([`users/detail/${this.userData.id}/wallet/${id}`]);
  }

  ngOnInit(): void {
    this.checkIsAuditor();
    this.checkAccessToProducts();
    this.getListWallets();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
