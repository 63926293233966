import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WalletService } from '../../../services/wallet/wallet.service';
@Component({
  selector: 'ngx-wallet-actions-modal',
  templateUrl: './wallet-actions-modal.component.html',
  styleUrls: ['./wallet-actions-modal.component.scss'],
})
export class WalletActionsModalComponent implements OnInit, OnDestroy {
  itemData;
  userId;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private fb: UntypedFormBuilder,
    private toaster: ToastrService,
    private walletService: WalletService,

    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}
  deactivateItem() {
    this.walletService
      .deactivateWallet(this.userId, this.itemData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно деактивировали!');
        this.dialogRef.close('edit');
      });
  }
  activateItem() {
    this.walletService
      .activateWallet(this.userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.toaster.success('Вы успешно активировали!');
        this.dialogRef.close('edit');
      });
  }

  ngOnInit(): void {}
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
