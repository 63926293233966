import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[ngxScrollToBottom]',
  standalone: true,
})
export class ScrollToBottomDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input() stayAtBottom: boolean = true;
  @Input('scrollNow$s') scrollNow: Subject<any> = new Subject();
  @Output() amScrolledToBottom: Observable<boolean> = new Observable();

  private destroy$ = new Subject();
  private changes: MutationObserver;

  private scrollEvent: Observable<{}>;
  private mutations = new BehaviorSubject(null);
  private _userScrolledUp = new BehaviorSubject<boolean>(false);

  constructor(private self: ElementRef) {}

  public ngOnChanges() {
    if (this.stayAtBottom === true) {
      this.scrollToBottom();
    }
  }

  public ngAfterViewInit() {
    this.registerScrollHandlers();
  }

  public ngOnDestroy() {
    this.destroy$.next(null);
    if (this.changes != null) {
      this.changes.disconnect();
    }
  }

  private registerScrollHandlers() {
    this.amScrolledToBottom = this._userScrolledUp.pipe(
      takeUntil(this.destroy$),
      map(x => !x),
    );

    new MutationObserver(() => this.mutations.next(null)).observe(this.self.nativeElement, {
      attributes: true,
      childList: true,
      characterData: true,
    });

    this.scrollEvent = fromEvent(this.self.nativeElement, 'scroll').pipe(takeUntil(this.destroy$), debounceTime(100));

    this.scrollNow.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.scrollToBottom();
    });

    this.mutations.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (this._userScrolledUp.value === false) {
        this.scrollToBottom();
      }
    });

    this.scrollEvent.pipe(takeUntil(this.destroy$)).subscribe(x => {
      this.setHasUserScrolledUp();
    });
  }

  private setHasUserScrolledUp() {
    const el = this.self.nativeElement;
    if (el.scrollHeight === el.clientHeight + el.scrollTop) {
      this._userScrolledUp.next(false);
      return;
    }

    if (this._userScrolledUp.value === true) {
      return;
    }

    setTimeout(() => {
      if (!(el.scrollHeight === el.clientHeight + el.scrollTop)) {
        this._userScrolledUp.next(true);
      }
    }, 5);
  }

  private scrollToBottom() {
    setTimeout(() => (this.self.nativeElement.scrollTop = this.self.nativeElement.scrollHeight), 0);
  }
}
