import { Pipe, PipeTransform } from '@angular/core';
import { residenceLocationEnum } from '@utils/creditAplicationData';

@Pipe({
  name: 'residenceLoc',
  standalone: true,
  pure: true,
})
export class ResidenceLocPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (!value) {
      return 'Не указано';
    }
    return residenceLocationEnum.find(e => e.value === value)?.text;
  }
}
