import { Component, OnInit, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { NbButtonModule, NbInputModule, NbPopoverModule } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-buttons',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NbInputModule, ReactiveFormsModule, NbButtonModule, NbPopoverModule],
  standalone: true,
})
export class ConfirmDialogComponent implements OnInit {
  @Output() approveEvent = new EventEmitter();
  @Input() control: AbstractControl = new UntypedFormControl();
  @Input() status = 'primary';
  @Input() title = 'подтвердите';
  @Input() ghost: boolean;
  @Input() disabled = false;
  @Input() textareaInclude = false;
  @Input() textareaTitle = 'Напишите своё предложение партнеру';

  visible: boolean;
  @Input() reason!: boolean;
  @Input() size!: string;

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    this.visible = true;
  }
  approve() {
    this.approveEvent.emit('');
    this.visible = false;
  }
  decline() {
    this.visible = false;
  }
}
