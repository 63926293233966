<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <label class="label">Новый пароль</label>
    <div class="d-flex">
      <input type="text" nbInput fullWidth placeholder="Новый пароль" formControlName="password" />
      <button type="button" class="ml-2" (click)="generatePassword()" nbButton status="primary">Сгенерировать</button>
    </div>
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1" class="label">Подтвердите пароль</label>
    <input type="text" nbInput fullWidth placeholder="Подтвердите пароль" formControlName="passwordConfirmation" />
  </div>

  <button class="d-flex mx-auto" type="submit" nbButton status="primary">Изменить пароль</button>
</form>
