import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { accountantRole } from '@utils/const';
import { ReadApiService } from '@services/@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class AccountantStaffService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private baseUrlOidc: string = this.environment.getApiKey('baseUrlOidc');

  getListStaff(page = 1, name = '') {
    return this.http.get(
      this.baseUrlOidc +
        `/Administration/api/v1/Users/Search?role=${accountantRole.id}&name=${name}&pageNumber=${page}&pageSize=20`,
    );
  }

  deleteStaff(id: string) {
    return this.http.delete(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`);
  }
}
