@if (userData$ | async) {
  <div class="user-profile">
    <table
      style="width: 100%; border-collapse: collapse"
      class="MsoNormalTable"
      border="0"
      cellspacing="0"
      cellpadding="0"
      width="100%">
      <tbody>
        <tr>
          <td width="4%">
            <p>
              <b><span>№</span></b>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <b><span>Наименование полей анкеты</span></b>
            </p>
          </td>
          <td width="30%">
            <p>
              <b><span>Сведения о клиенте</span></b>
            </p>
          </td>
        </tr>
        <tr>
          <td width="100%" colspan="3">
            <p>
              <b><span>1. Идентификационные сведения</span></b>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>1)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Статус клиента </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> Резидент</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>2)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Фамилия</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span>{{ userData?.surname }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>3)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Имя</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ userData?.name }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>4)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Отчество </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ userData?.patronymic }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>5)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Дата рождения</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ userData?.birthDate | date: 'dd.MM.yyyy' }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>6)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Место рождения </span>
            </p>
          </td>
          <td width="30%">
            <p><span> </span></p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>7)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Национальность </span>
            </p>
          </td>
          <td width="30%">
            <p><span> </span></p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>8)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Пол</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ gender }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>9)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Гражданство</span>
            </p>
          </td>
          <td width="30%">
            <p><span> Кыргызская Республика </span></p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>10)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Семейное положение </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ maritalStatus }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td rowspan="7" width="4%">
            <p>
              <span>11)</span>
            </p>
          </td>
          <td width="95%" colspan="2">
            <p>
              <span>Реквизиты документа, удостоверяющего личность:</span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>11.1. Наименование документа</span>
            </p>
          </td>
          <td width="30%">
            <p><span> Идентификационная карта </span></p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>11.2. Серия и номер документа</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span>
                {{ userData?.passport.documentType + ' ' + userData?.passport.documentNumber }}
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>11.3. Дата выдачи документа</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span>
                {{ userData?.passport.dateOfIssue | date: 'dd.MM.yyyy' }}
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>11.4. Дата окончания срока действия документа</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span>
                {{ userData?.passport.dateOfExpiry | date: 'dd.MM.yyyy' }}
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>11.5. Наименование органа, выдавшего документ</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span>{{ userData?.passport.authorityName }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>11.6. Код подразделения </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ userData?.passport.authority }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>12)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Персональный идентификационный номер</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ userData?.pin }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>13)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Адрес места регистрации </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ residenceLoc + ', ' + userData?.residenceAddress.address }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>14)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Адрес места фактического проживания или пребывания </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span>
                {{ actualLoc + ',' + userData?.actualAddress.locality + ',' + userData?.actualAddress.street }}</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td rowspan="4" width="4%">
            <p>
              <span>15)</span>
            </p>
          </td>
          <td width="95%" colspan="2">
            <p>
              <span>Контактные данные клиента:</span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>15.1. Номера телефонов </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> +{{ userData?.phoneNumber }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>15.2. Номер факса </span>
            </p>
          </td>
          <td width="30%">
            <p><span> </span></p>
          </td>
        </tr>
        <tr>
          <td class="title-td" width="64%">
            <p>
              <span>15.3. Адрес электронной почты </span>
            </p>
          </td>
          <td width="30%">
            <p><span> </span></p>
          </td>
        </tr>
        <tr>
          <td width="100%" colspan="3">
            <p>
              <b><span>2. Сведения о деловом профиле клиента</span></b>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>17)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Цель и предполагаемый характер деловых отношений клиента</span>
            </p>
          </td>
          <td width="30%">
            <p><span> Открытие кошелька </span></p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>18)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Является ли клиент публичным должностным лицом (ПДЛ) </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> Нет</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>19)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Сведения о наличии у клиента бенефициарного владельца </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> Не имеется</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>20)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span
                >Сведения о документах клиента, подтверждающих полномочия по распоряжению денежными средствами или
                имуществом
              </span>
            </p>
          </td>
          <td width="30%">
            <p><span> </span></p>
          </td>
        </tr>
        <tr>
          <td width="100%" colspan="3">
            <p>
              <b
                ><span>3. Информация о верификации, проверке и уровне риска клиента<br /></span
              ></b>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>23)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Сведения о проведении верификации клиента и о результатах верификации </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> Проведена</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>24)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span
                >Сведения о проверке клиента в Санкционных перечнях Кыргызской Республики и о результатах проверки</span
              >
            </p>
          </td>
          <td width="30%">
            <p>
              <span>
                {{
                  blackListData?.blackListPerson?.source === blackListTypes.EauBlackListPersonsParser
                    ? 'Обнаружен'
                    : 'Отсутствует'
                }}
                в Перечне</span
              >
            </p>
            <p>
              <span> Дата и время проверки: {{ userData?.createdAt | date: 'dd.MM.yyyy HH:mm' }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>25)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span
                >Сведения о проверке клиента в Перечне лиц, групп и организаций, в отношении которых имеются сведения об
                их участии в легализации (отмывании) преступных доходов, и о результатах проверки</span
              >
            </p>
          </td>
          <td width="30%">
            <p>
              <span
                >{{
                  blackListData?.blackListPerson?.source === blackListTypes.PartipicationPersonsBlackListParser
                    ? 'Обнаружен'
                    : 'Отсутствует'
                }}
                в Перечне</span
              >
            </p>
            <p>
              <span>Дата и время проверки: {{ userData?.createdAt | date: 'dd.MM.yyyy HH:mm' }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>26)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span
                >Сведения о проверке клиента в Перечне физических лиц, отбывших наказание за осуществление легализации
                (отмывания) преступных доходов, террористической или экстремистской деятельности, а также за
                финансирование данной деятельности, и о результатах проверки
              </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span
                >{{
                  blackListData?.blackListPerson?.source === blackListTypes.PersonsServedSentenceBlackListParser
                    ? 'Обнаружен'
                    : 'Отсутствует'
                }}
                в Перечне</span
              >
            </p>
            <p>
              <span> Дата и время проверки: {{ userData?.createdAt | date: 'dd.MM.yyyy HH:mm' }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>27)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Степень (уровень) риска </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ blackListData?.similarity > 0.8 ? 'Высокий риск' : 'Низкий риск' }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>28)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Обоснование оценки степени (уровня) риска (по критериям высокого риска)</span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span>{{ blackListData?.similarity > 0.8 ? convertToPercentage(blackListData?.similarity) : '' }}</span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>29)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span
                >Дата заполнения или последнего обновления сведений, изложенных в анкете (в случае отсутствия
                существенных изменений и дополнений в анкете клиента, новая анкета не заполняется)</span
              >
            </p>
          </td>
          <td width="30%">
            <p>
              <span>{{ userData?.createdAt | date: 'dd.MM.yyyy' }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>30)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span>Дата очередного обновления сведений, изложенных в анкете </span>
            </p>
          </td>
          <td width="30%">
            <p>
              <span> {{ userData?.createdAt | date: 'dd.MM.yyyy' }} </span>
            </p>
          </td>
        </tr>
        <tr>
          <td width="4%">
            <p>
              <span>31)</span>
            </p>
          </td>
          <td class="title-td" width="64%">
            <p>
              <span
                >Дата занесения в базу данных информации, указанной в настоящей анкете, и ФИО ответственного сотрудника
                финансового учреждения и нефинансовой категории лиц</span
              >
            </p>
          </td>
          <td width="30%">
            <p>
              @if (operatorName$ | async; as operatorName) {
                <span>{{ operatorName }}</span>
              }
            </p>
            <p>
              <span>Дата: {{ userData?.createdAt | date: 'dd.MM.yyyy' }}</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}
