import { inject, Injectable } from '@angular/core';
import { Observable, of, startWith } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DownloadImageService {
  private readonly errorImagePath = '/assets/error-image.webp';
  private readonly loadingImagePath = '/assets/loading.gif';
  private httpClient: HttpClient = inject(HttpClient);
  private domSanitizer: DomSanitizer = inject(DomSanitizer);

  public loadImage(imagePath: string): Observable<SafeUrl> {
    return this.loadImageFromHttp(imagePath).pipe(
      startWith(this.loadingImagePath),
      map((imageUrl: string) => this.domSanitizer.bypassSecurityTrustUrl(imageUrl)),
      catchError(() => of(this.errorImagePath)),
    );
  }

  private loadImageFromHttp(imagePath: string): Observable<string> {
    return this.httpClient
      .get(imagePath, { observe: 'response', responseType: 'blob' })
      .pipe(map((response: HttpResponse<Blob>) => URL.createObjectURL(response.body)));
  }
}
