<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"> <span>cash2u</span></a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    @for (theme of themes; track theme) {
      <nb-option [value]="theme.value"> {{ theme.name }}</nb-option>
    }
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user
        nbContextMenuTag="my-context-menu"
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="shortName(user?.name)"
        [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
