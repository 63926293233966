@if (staffDetail) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label class="label">Ф.И.О</label>
      <input type="text" nbInput fullWidth placeholder="Ф.И.О" formControlName="name" />
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1" class="label">Логин</label>
      <input type="text" nbInput fullWidth placeholder="Логин" formControlName="userName" />
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1" class="label">Роли</label>
      <ngx-search-select
        (searchEmit)="getRoles()"
        [control]="form.get('roles')"
        [isRequired]="true"
        [submitted]="submitted"
        [data]="roles"
        returnVal="title"
        [mode]="roleSelectMode"
        [placeholder]="'Выберите роли'"></ngx-search-select>
    </div>
    <button class="d-flex mx-auto" type="submit" nbButton status="primary">Редактировать</button>
  </form>
}
