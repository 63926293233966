import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReadApiService } from '@services/@core/config/read-api.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private baseUrl: string = this.environment.getApiKey('baseUrl');

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.skipUrl(request.url)) {
      return next.handle(request);
    }
    const apiReq = request.clone({
      url: `${this.baseUrl}/${request.url}`,
    });
    return next.handle(apiReq);
  }

  private skipUrl(url: string): boolean {
    if (url.toLowerCase().startsWith('partners')) {
      return false;
    }
    return true;
  }
}
