@if (imgUrl() || safeImgUrl()) {
  <div class="image-container">
    <img alt="image" width="80%" (click)="openImage()" [src]="imgUrl() ? imgState() : safeImgUrl()" />
  </div>
  @if (open()) {
    <div class="viewer-container">
      <div class="viewer-content">
        <button class="close-button" (click)="open.set(false)">
          <i style="font-size: 50px" class="nb-close"></i>
        </button>
        <div class="image-container-light" #imageContainer>
          <img
            alt="image"
            [src]="imgUrl() ? imgState() : safeImgUrl()"
            class="main-img main-img-desktop"
            #imageElement
            (dblclick)="zoomIn()"
            (mouseup)="onImageMouseUp($event)"
            (mousedown)="onImageMouseDown($event)"
            (mousemove)="onImageMouseMove($event)"
            (dragstart)="onImageMouseMove($event)"
            (wheel)="onImageWheel($event)" />
        </div>
        <div class="controls">
          <button nbButton (click)="zoomOut()">-</button>
          <button nbButton (click)="zoomIn()">+</button>
          <button nbButton (click)="rotateLeft()">↺</button>
          <button nbButton (click)="rotateRight()">↻</button>
        </div>
      </div>
    </div>
  }
}
@if (img$ | async) {}
