import { Pipe, PipeTransform } from '@angular/core';
import { genderEnum } from '@utils/creditAplicationData';

@Pipe({
  name: 'gender',
  standalone: true,
  pure: true,
})
export class GenderPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (!value) {
      return 'Не указано';
    }
    return genderEnum.find(e => e.value === value).text;
  }
}
