import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { adminRole } from '@utils/const';
import { Observable } from 'rxjs';
import { ConfirmPassword, CreateUser, Pagination, Role, User, UserBase } from '@models/@core/common/common.model';
import { ReadApiService } from '@services/@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminStaffService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private baseUrlOidc: string = this.environment.getApiKey('baseUrlOidc');

  getListStaff(page: number = 1, name: string = ''): Observable<Pagination<User>> {
    return this.http.get<Pagination<User>>(
      this.baseUrlOidc +
        `/Administration/api/v1/Users/Search?role=${adminRole.id}&name=${name}&pageNumber=${page}&pageSize=20`,
    );
  }

  getDetailStaff(id: string): Observable<User> {
    return this.http.get<User>(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`);
  }

  deleteStaff(id: string): Observable<void> {
    return this.http.delete<void>(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`);
  }

  editStaff(id: string, data: UserBase): Observable<User> {
    return this.http.put<User>(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`, data);
  }

  getRolesStaff(): Observable<Role[]> {
    return this.http.get<Role[]>(this.baseUrlOidc + `/Administration/api/v1/Roles/List`);
  }

  changeStaffPassword(id: string, data: ConfirmPassword): Observable<void> {
    return this.http.post<void>(this.baseUrlOidc + `/Administration/api/v1/Users/${id}/ChangePassword`, data);
  }

  createStaff(data: CreateUser): Observable<User> {
    return this.http.post<User>(this.baseUrlOidc + `/Administration/api/v1/Users`, data);
  }
}
