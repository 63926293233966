import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ngx-custom-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @Output() deleteItemEvent = new EventEmitter();
  @Output() openModalEvent = new EventEmitter();
  @Output() rowSelectEvent = new EventEmitter();
  @Output() changePageEvent = new EventEmitter();

  @Input() tableColumns;
  @Input() tableData;
  @Input() productName;
  @Input() delete = true;
  @Input() edit = true;
  @Input() filterForm;
  @Input() actions = true;
  settings = {};
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private windowService: NbWindowService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  onDelete(event) {
    this.deleteItemEvent.emit(this.productName === 'ни одного отзыва' ? event.data : event.data.id);
  }

  onRowSelect(event) {
    this.setParams();
    setTimeout(() => {
      this.rowSelectEvent.emit(event.data.id);
    }, 1);
  }

  onEdit(event) {
    this.openModalEvent.emit(event.data);
  }

  changePage(page) {
    if (this.filterForm) {
      this.filterForm.controls.page.setValue(page);
      return;
    }
    this.changePageEvent.emit(page);
  }

  setParams() {
    if (this.filterForm) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: this.filterForm.value,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  ngOnInit(): void {
    this.settings = {
      mode: 'external',
      hideSubHeader: true,
      delete: {
        deleteButtonContent: `<i class="nb-trash"></i>`,
        confirmDelete: false,
      },
      edit: {
        editButtonContent: '<i class="nb-edit"></i>',
      },

      actions: this.actions
        ? {
            delete: this.delete,
            edit: this.edit,
            add: false,
            position: 'right',
            columnTitle: 'Опции',
          }
        : false,
      pager: {
        perPage: 20,
        display: true,
      },

      // noDataMessage: 'Список пуст!',
      columns: this.tableColumns,
    };

    if (this.filterForm) {
      this.filterForm.valueChanges.pipe(takeUntil(this.destroy$), pairwise()).subscribe(([prev, curr]) => {
        const { page: prevPage, ...prevObj } = prev;
        const { page: currPage, ...currObj } = curr;

        if (JSON.stringify(prevObj) !== JSON.stringify(currObj) && +currPage !== 1) {
          this.filterForm.controls.page.setValue(1);
        }
      });
      this.filterForm.patchValue(this.route.snapshot.queryParams);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.cd.markForCheck();
    }, 0);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
