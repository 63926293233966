import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { auditorRole } from '../../../utils/const';
@Component({
  selector: 'ngx-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.scss'],
})
export class EditStaffComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  staffService;
  staffDetail;
  roles = [];
  selectedRoles;
  isAdmin;
  roleSelectMode = 'multiple';
  submitted = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private toaster: ToastrService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.getRoles();

    this.form = this.fb.group({
      name: ['', Validators.required],
      userName: ['', Validators.required],
      roles: [[], [Validators.required]],
    });
    this.form.controls['roles'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (auditorRole.id === data) {
        // this.roleSelectMode = 'multiple';
      }
    });
    if (this.staffDetail) {
      this.roleSelectMode = this.staffDetail.roles.length > 1 ? 'multiple' : 'default';
      this.form.controls['name'].setValue(this.staffDetail.name);
      this.form.controls['userName'].setValue(this.staffDetail.userName);
      this.form.controls['roles'].setValue(
        this.staffDetail.roles.length > 1 ? this.staffDetail.roles.map(item => item.id) : this.staffDetail.roles[0].id,
      );
    }
  }
  getRoles() {
    this.staffService
      .getRolesStaff()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.roles = data.filter(item => this.selectedRoles.some(role => role.id === item.id));
      });
  }
  onSubmit() {
    if (this.form.valid) {
      const roles = this.form.value.roles;
      this.staffService
        .editStaff(this.staffDetail.id, {
          ...this.form.value,
          roles: Array.isArray(roles) ? roles : [roles],
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toaster.success('Успешно отредактировано!');
          this.dialogRef.close('edit');
        });
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
