<ngx-custom-table
  (changePageEvent)="getListWallets($event)"
  (openModalEvent)="openEditModal($event)"
  (rowSelectEvent)="onRowSelect($event)"
  [tableColumns]="tableColumns"
  [tableData]="listWallets"
  [productName]="'ни одного кошелька'"
  [delete]="false"
  [actions]="false"
  [edit]="hasAccesToWalletActions && !isAuditor">
</ngx-custom-table>
