import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { getTimeWithTimezone } from '../../utils';
import { Observable } from 'rxjs';
import { CustomerProfile } from '@models/@core/common/common.model';
import { IIdentificationDetail } from '@models/identification/identification';
import { ReadApiService } from '@services/@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private identificationUrl: string = this.environment.getApiKey('identificationUrl');
  private walletSystemUrl: string = this.environment.getApiKey('walletSystemUrl');

  getDetailUser(id: string) {
    return this.http.get(this.identificationUrl + `/operator/api/v1/customers/${id}`);
  }

  getListUsers(filter) {
    return this.http.get(
      this.identificationUrl +
        `/operator/api/v1/customers?dateFrom=${filter.from && filter.from + getTimeWithTimezone()}&dateTo=${
          filter.to && filter.to + getTimeWithTimezone()
        }&name=${filter.name}&surname=${filter.surname}&patronymic=${filter.patronymic}${
          filter.status ? `&statusList=${filter.status}` : `&statusList=Offline&statusList=Online`
        }&phoneNumber=${filter.phone}&orderBy=CreatedAt&orderType=desc&page=${filter.page}&pageSize=20`,
    );
  }

  downloadListUsers(filter) {
    return this.http.get(
      this.identificationUrl +
        `/operator/api/v1/customers/excel-download?dateFrom=${
          filter.from && filter.from + getTimeWithTimezone()
        }&dateTo=${
          filter.to && filter.to + getTimeWithTimezone()
        }&name=${filter.name}&surname=${filter.surname}&patronymic=${filter.patronymic}&phoneNumber=${
          filter.phone
        }&orderBy=CreatedAt&orderType=desc&page=${filter.page}&pageSize=20`,
      { responseType: 'blob' },
    );
  }

  getReportsListUsers(filter) {
    const params = {
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
    };
    return this.http.get(
      this.walletSystemUrl +
        `/DosCredoBank/api/v1/customers?orderBy=CreatedAt&orderType=desc&page=${filter.page}&pageSize=20`,
      { params },
    );
  }

  downloadReportsListUsers(filter) {
    const params = {
      dateFrom: filter.dateFrom,
      dateTo: filter.dateTo,
    };
    return this.http.get(this.walletSystemUrl + `/DosCredoBank/api/v1/customers/excel-download`, {
      params,
      responseType: 'blob',
    });
  }

  getUserProfileData(id: string): Observable<CustomerProfile> {
    return this.http.get<CustomerProfile>(this.identificationUrl + `/operator/api/v1/customers/${id}`);
  }

  getIdentificationRequest(id: string): Observable<any> {
    return this.http.get<IIdentificationDetail>(
      this.identificationUrl + `/Admin/api/v1/identification-requests?customerId=${id}`,
    );
  }
}
