import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SimilarityItem } from '../../models/@core/common/common.model';
import { ReadApiService } from '../@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationRequestsService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private blackListPersonUrl: string = this.environment.getApiKey('blackListPersonUrl');

  public getBlackListPerson(name: string, customerId?: string, birthDate?: string): Observable<SimilarityItem[]> {
    const queryParams = new URLSearchParams({
      fullname: name,
      ...(customerId && { customerId }),
      ...(birthDate && { birthDate }),
    }).toString();

    const url = `${this.blackListPersonUrl}/DosCredoBank/api/v1/blacklist/search?${queryParams}`;

    return this.http.get<SimilarityItem[]>(url);
  }
}
