import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  NbAccordionModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbPopoverModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbTreeGridModule,
} from '@nebular/theme';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { ValidationInputComponent } from './shared/validation-input/validation-input.component';
import { TableComponent } from './shared/table/table.component';

import { StatusBadgeComponent } from './shared/status-badge/status-badge.component';
import { BtnLoaderComponent } from './shared/btn-loader/btn-loader.component';
import { InputSearchComponent } from './shared/input-search/input-search.component';

import { CustomDatePipe } from './shared/date-pipe/date.pipe';
import { UserWalletComponent } from './users/wallet/user-wallet.component';
import { WalletActionsModalComponent } from './users/wallet-actions-modal/wallet-actions-modal.component';
import { DateFilterBtnsComponent } from './shared/date-filter-btns/date-filter-btns.component';
import { EditStaffComponent } from './staff/edit-staff/edit-staff.component';
import { ChangePasswordModalComponent } from './staff/change-password-modal/change-password-modal.component';
import { OperatorsListStaffComponent } from './staff/list-operator-staff/staff.component';
import { ManagersListStaffComponent } from './staff/list-managers-staff/staff.component';
import { BankSpecialistListStaffComponent } from './staff/bank-specialist-list-staff/staff.component';
import { AddBlackListPersonModal } from './black-list/add-black-list-person-modal/add-black-list-person-modal';
import { BlackListPersonComponent } from './black-list/black-list-person/black-list-person.component';
import { AuditorsListStaffComponent } from './staff/list-auditors-staff/staff.component';
import { AccountantsListStaffComponent } from './staff/list-accountant-staff/staff.component';
import { SupervisorsListStaffComponent } from './staff/list-supervisor-staff/staff.component';
import { CallCenterListStaffComponent } from './staff/list-call-center-staff/staff.component';
import { AmlSpecialistStaffComponent } from './staff/aml-specialist-list-staff/staff.component';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { SearchSelectComponent } from './shared/search-select/search-select.component';
import { AvatarImgComponent } from './shared/avatar-img/avatar-img.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { LightboxImgComponent } from './shared/lightbox-img/lightbox-img.component';
import { CreateStaffModalComponent } from './staff/create-staff-modal/create-staff-modal/create-staff-modal.component';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { DemoNgZorroAntdModule } from '@utils/ng-zorro-antd.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NbMomentDateModule } from '@nebular/moment';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ReactiveFormsModule } from '@angular/forms';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { ScrollToBottomDirective } from '../directives/scroll-to-bottom.directive';
import { CdkScrollable } from '@angular/cdk/overlay';
import { OrderByPipe } from '@pipes/order-by.pipe';
import { GenderPipe } from '@pipes/gender.pipe';
import { ResidenceLocPipe } from '@pipes/residence-loc.pipe';
import { MaritalStatusPipe } from '@pipes/marital-status.pipe';
import { UseHttpImageSourcePipe } from '@pipes/secured-image.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NbAlertModule,
    NbStepperModule,
    NbCardModule,
    ReactiveFormsModule,
    Angular2SmartTableModule,
    NbButtonModule,
    NbSelectModule,
    NbInputModule,
    NbPopoverModule,
    NbListModule,
    NbDatepickerModule,
    NzPaginationModule,
    NzSelectModule,
    NbTreeGridModule,
    NbAccordionModule,
    NbTabsetModule,
    NbIconModule,
    NzSliderModule,
    DemoNgZorroAntdModule,
    NbEvaIconsModule,
    NbBadgeModule,
    NzResultModule,
    NbCheckboxModule,
    NbMomentDateModule,
    NbSpinnerModule,
    ScrollToBottomDirective,
    CdkScrollable,
    OrderByPipe,
    GenderPipe,
    ResidenceLocPipe,
    MaritalStatusPipe,
    UseHttpImageSourcePipe,
    ConfirmDialogComponent,
  ],
  declarations: [
    CreateStaffModalComponent,
    SearchSelectComponent,
    AvatarImgComponent,
    PaginationComponent,
    LightboxImgComponent,
    ValidationInputComponent,
    TableComponent,
    StatusBadgeComponent,
    BtnLoaderComponent,
    InputSearchComponent,
    CustomDatePipe,
    UserWalletComponent,
    WalletActionsModalComponent,
    DateFilterBtnsComponent,
    EditStaffComponent,
    ChangePasswordModalComponent,
    OperatorsListStaffComponent,
    ManagersListStaffComponent,
    BankSpecialistListStaffComponent,
    AddBlackListPersonModal,
    BlackListPersonComponent,
    AuditorsListStaffComponent,
    AccountantsListStaffComponent,
    SupervisorsListStaffComponent,
    CallCenterListStaffComponent,
    AmlSpecialistStaffComponent,
    UserProfileComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    CreateStaffModalComponent,
    SearchSelectComponent,
    AvatarImgComponent,
    PaginationComponent,
    LightboxImgComponent,
    ValidationInputComponent,
    TableComponent,
    StatusBadgeComponent,
    BtnLoaderComponent,
    InputSearchComponent,
    CustomDatePipe,
    UserWalletComponent,
    WalletActionsModalComponent,
    DateFilterBtnsComponent,
    EditStaffComponent,
    ChangePasswordModalComponent,
    OperatorsListStaffComponent,
    ManagersListStaffComponent,
    BankSpecialistListStaffComponent,
    AddBlackListPersonModal,
    BlackListPersonComponent,
    AuditorsListStaffComponent,
    AccountantsListStaffComponent,
    SupervisorsListStaffComponent,
    CallCenterListStaffComponent,
    AmlSpecialistStaffComponent,
    UserProfileComponent,
  ],
})
export class ComponentsModule {}
