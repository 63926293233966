import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
  standalone: true,
  pure: true,
})
export class OrderByPipe<T> implements PipeTransform {
  transform(value: T[], order: 'createdAt' = 'createdAt'): T[] {
    if (!value || value.length <= 1) {
      return value;
    }
    return [...value].sort((a: any, b: any) => new Date(a!.createdAt).valueOf() - new Date(b!.createdAt).valueOf());
  }
}
