import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NbWindowService } from '@nebular/theme';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { tableNumbering } from '../../../utils';
import { CreateStaffModalComponent } from '../create-staff-modal/create-staff-modal/create-staff-modal.component';
import { AdminStaffService } from '../../../services/staff/admin-staff.service';
import { supervisorRole } from '../../../utils/const';
import { SupervisorStaffService } from '../../../services/staff/supervisor-staff.service';
import { getPaginationIndex } from '../../../utils/tableNumbering';

@Component({
  selector: 'ngx-supervisors-staff-list',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss'],
})
export class SupervisorsListStaffComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isAdmin: boolean;
  @Input() updateList: boolean;
  subscription: Subscription;
  listStaff;
  visible: boolean;
  tableColumns = {
    index: {
      title: '№',
      type: 'number',
      valuePrepareFunction: (value, row, cell) =>
        getPaginationIndex(this.listStaff.pageNumber, row.row.index, this.listStaff.pageSize),
    },

    name: {
      title: 'ФИО',
      type: 'html',
    },

    userName: {
      title: 'Логин',
      type: 'text',
    },
    roles: {
      title: 'Роль',
      type: 'text',
      valuePrepareFunction: value => this.getRoles(value),
    },
  };
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private staffService: SupervisorStaffService,
    private adminStaffService: AdminStaffService,
    private router: Router,
    public windowService: NbWindowService,
    private toaster: ToastrService,
  ) {}

  ngOnChanges(changes): void {
    if (changes.updateList.currentValue) {
      this.getListStaff();
    }
  }

  ngOnInit(): void {
    this.getListStaff();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getListStaff(page = 1, name = '') {
    this.subscription = this.staffService.getListStaff(page, name).subscribe({
      next: data => (this.listStaff = data),
    });
  }

  onSearch(name) {
    this.getListStaff(1, name);
  }

  onDelete(id): void {
    this.staffService
      .deleteStaff(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.toaster.success('Успешно удалено!');
          this.getListStaff(1);
        },
      });
  }

  openEdit(data) {
    this.router.navigate([`staff/supervisor/${data.id}`]);
  }

  getRoles(item) {
    return item.length > 1 ? item.map(elem => ' ' + elem.title) : item[0].title;
  }

  openCreateModal() {
    this.openModal(false, CreateStaffModalComponent, {
      title: 'Добавление сотрудника',
      context: {
        staffService: this.isAdmin ? this.adminStaffService : this.staffService,
        selectedRole: supervisorRole,
      },
    });
  }

  openModal(closeOnBackdropClick: boolean, component, props) {
    this.windowService
      .open(component, {
        closeOnBackdropClick,
        ...props,
      })
      .onClose.subscribe(val => val === 'create' && this.getListStaff());
  }
}
