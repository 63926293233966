import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Transaction } from '../../views/black-list-person/models/black-list.model';
import { Observable } from 'rxjs';
import { Pagination, SimilarityItem } from '@models/@core/common/common.model';
import { ReadApiService } from '@services//@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class BlackListPersonService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private blackListPersonUrl: string = this.environment.getApiKey('blackListPersonUrl');

  public getBlackListPerson(name: string, customerId?: string, birthDate?: string): Observable<SimilarityItem[]> {
    const queryParams = new URLSearchParams({
      fullname: name,
      ...(customerId && { customerId }),
      ...(birthDate && { birthDate }),
    }).toString();

    const url = `${this.blackListPersonUrl}/DosCredoBank/api/v1/blacklist/search?${queryParams}`;

    return this.http.get<SimilarityItem[]>(url);
  }

  getBlackList(page: number, name: string) {
    return this.http.get<Pagination<SimilarityItem>>(
      this.blackListPersonUrl + `/DosCredoBank/api/v1/blacklist/search?fullname=${name}&pageNumber=${page}&pageSize=20`,
    );
  }

  getBlackListMatches(page: number, similarityType: number, name?: string, surname?: string, patronymic?: string) {
    let params = new HttpParams()
      .set('SimilarityType', similarityType.toString())
      .set('Page', page.toString())
      .set('pageSize', '20')
      .set('orderBy', 'CreatedAt')
      .set('orderType', 'desc');

    if (name) {
      params = params.set('name', name);
    }

    if (surname) {
      params = params.set('surname', surname);
    }

    if (patronymic) {
      params = params.set('patronymic', patronymic);
    }

    return this.http.get<Pagination<Transaction>>(this.blackListPersonUrl + '/DosCredoBank/api/v1/terrorist-actions', {
      params: params,
    });
  }

  addPersonToBlackList(data: string) {
    return this.http.post(this.blackListPersonUrl + `/DosCredoBank/api/v1/blacklist/load`, data);
  }
}
