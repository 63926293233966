import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ReadApiService } from '@services/@core/config/read-api.service';

@Injectable({
  providedIn: 'root',
})
export class AuditorsStaffService {
  private readonly environment: ReadApiService = inject(ReadApiService);
  private readonly http: HttpClient = inject(HttpClient);
  private baseUrlOidc: string = this.environment.getApiKey('baseUrlOidc');

  getListStaff(page = 1, name = '') {
    return this.http.get(
      this.baseUrlOidc +
        `/Administration/api/v1/Users/Search?role=13bfdca3-f1c0-470a-a546-999f5f266dd1&name=${name}&pageNumber=${page}&pageSize=20`,
    );
  }

  getDetailStaff(id: string) {
    return this.http.get(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`);
  }

  deleteStaff(id: string) {
    return this.http.delete(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`);
  }

  editStaff(id: string, data) {
    return this.http.put(this.baseUrlOidc + `/Administration/api/v1/Users/${id}`, data);
  }

  getRolesStaff() {
    return this.http.get(this.baseUrlOidc + `/Administration/api/v1/Roles/List`);
  }

  changeStaffPassword(id: string, data) {
    return this.http.post(this.baseUrlOidc + `/Administration/api/v1/Users/${id}/ChangePassword`, data);
  }

  createStaff(data) {
    return this.http.post(this.baseUrlOidc + `/Administration/api/v1/Users`, data);
  }
}
