import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NbWindowRef } from '@nebular/theme';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { GeneratePassword } from '../../../../utils';
import { auditorRole } from '../../../../utils/const';

@Component({
  selector: 'ngx-create-staff-modal',
  templateUrl: './create-staff-modal.component.html',
  styleUrls: ['./create-staff-modal.component.scss'],
})
export class CreateStaffModalComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  staffService;
  submitted = false;
  roles = [];
  selectedRoles;
  isAdmin;
  roleSelectMode = 'default';
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private toaster: ToastrService,
    @Optional() private dialogRef: NbWindowRef<any>,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(256)]],
      userName: ['', [Validators.required, Validators.maxLength(256)]],
      roles: [[], [Validators.required]],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
    });
    this.form.controls['roles'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (auditorRole.id === data) {
        this.roleSelectMode = 'multiple';
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRoles(name) {
    this.staffService
      .getRolesStaff()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.roles = data.filter(item => this.selectedRoles.some(role => role.id === item.id));
      });
  }

  generatePassword() {
    const password = GeneratePassword();
    this.form.controls['password'].setValue(password);
    this.form.controls['passwordConfirmation'].setValue(password);
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.valid) {
      const roles = this.form.value.roles;
      this.staffService
        .createStaff({
          ...this.form.value,
          roles: Array.isArray(roles) ? roles : [roles],
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.toaster.success('Успешно создано!');
          this.dialogRef.close('create');
        });
    }
  }
}
