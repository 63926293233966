import { NgModule, inject } from '@angular/core';
import { AuthModule, OpenIdConfiguration, StsConfigLoader, AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { LocalStorageService } from '@services/@core/browser/local-storage.service';
import { Observable, of } from 'rxjs';
import { ReadApiService } from '@services/@core/config/read-api.service';

export const state = {
  enabled: true,
  disabled: false,
};

// Custom loader to provide configuration
class CustomStsConfigLoader implements StsConfigLoader {
  constructor(private readonly readApiService: ReadApiService) {}

  public loadConfigs(): Observable<OpenIdConfiguration[]> {
    const config: OpenIdConfiguration = {
      authority: this.readApiService.getApiKey('baseUrlOidc'),
      redirectUrl: `${(window as Window).location.origin}/cb`, // Cast window to Window
      postLogoutRedirectUri: `${(window as Window).location.origin}/logout`, // Cast window to Window
      clientId: 'admin_idp_client',
      scope: 'openid profile roles offline_access IdentityServerApi', // 'openid profile' + your scopes
      responseType: 'code',
      silentRenew: state.enabled,
      useRefreshToken: state.enabled,
      renewTimeBeforeTokenExpiresInSeconds: 30,
    };

    // Return the configuration as an array within an observable
    return of([config]);
  }
}

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: () => new CustomStsConfigLoader(inject(ReadApiService)), // Provide custom loader
        deps: [ReadApiService], // Ensure ReadApiService is injected
      },
    }),
  ],
  providers: [
    {
      provide: AbstractSecurityStorage,
      useClass: LocalStorageService, // Custom storage service
    },
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
